@import "config/config";
@import "helpers/animations";
@import "helpers/fontawesome"; // fa-pseudo
@import "helpers/media-queries";
@import "helpers/units";

/**
  * Markup:
  * <body class="--modal-open"></body>
  *
  * <div role="dialog" aria-modal="true" aria-labelledby="title-id" aria-describedby="description-id" hidden
  *      class="ui-component-modal
  *             --initial // [0]
  *             width:{sm, md, lg*}
  *             scroll:{viewport*, modal} // [1]
  *             close:{viewport, modal*} // [4]
  *             reveal:{fade-up*, slide-left, slide-down}
  *             bg:{overlay*, blur}
  *             dismiss:{absolute*, header}
  *             variant:{filters, exit, venue-hero, package-voucher, unavailable}
  *             no-style"> // [3]
  *   <div id="modal-id" tabindex="0"> // ID on this element for focus reasons
  *     Dismiss button absolutely-positioned (default):
  *     <button class="ui-component-button variant:dismiss">
  *       <span>Close</span>
  *     </button>
  *     <article>
  *       Content nodes here // [2]
  *     </article>
  *
  *     Dismiss button within header (`dismiss:header` variant):
  *     <article>
  *       <header class="ui-component-text-group variant:pull-left">
  *         <button class="ui-component-button variant:dismiss">
  *           <span>Close</span>
  *         </button>
  *         Other header content here
  *       </header>
  *       Content nodes here // [2]
  *     </article>
  *   </div>
  * </div>
  *
  * Note[0]: Use the `--initial` modifier class to represent the initial state of the modal, meaning
  * the `hidden` attribute can be added/removed without triggering modal animation.
  *
  * Note[1]: The modal is scrollable in the viewport by default. Use
  * `scroll:modal` to make it fixed in the viewport and scrollable inside the
  * modal instead. In this case, if a user action is present it's recommended
  * to wrap it in a `.ui-component-status-bar` so it's always visible
  * regardless of scroll height.
  *
  * Note[2]: Content nodes are expected to be other components, or have their own styles.
  * Default padding is applied to the first child node if it doesn't have a `class`
  * attribute.
  *
  * Note[3]: Use the `no-style` class to render the modal content inline on the page, as if not inside
  * a modal at all (no styles applied and dismiss button hidden).
  *
  * Note[4]: The "x" close button is positioned at top right of the modal by default. Use
  * `close:viewport` to hide this and show a pseudo button at top right of the viewport instead.
  */
.ui-component-modal:not(.no-style) {
  --modal-padding: var(--space);
  --modal-gap: var(--space-sm);
  --modal-max-height: none;
  --modal-nominal-max-height: calc(100% - 2 * var(--modal-vertical-margin));
  --modal-vertical-margin: var(--space);
  --fade-in-duration: .1s; // See `FADE_IN_DURATION`

  display: flex;
  align-items: center;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: $z-index-modal;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;

  &, &.bg\:overlay {
    background: rgba($black, 0.8);
  }

  &.bg\:blur {
    backdrop-filter: blur(4px);
  }

  > [tabindex] {
    position: relative; // for absolute dismiss button
    overflow-x: hidden;
    width: 100%;
    max-width: var(--modal-max-width);
    padding: var(--modal-padding);
    background-color: #fff;
    -webkit-backface-visibility: hidden;

    &:focus {
      outline: none; // hide outline if modal is focusable, as it's not a control
    }

    > article,
    > turbo-frame > article {
      display: flex;
      flex-direction: column;
      gap: var(--modal-gap);
      height: 100%;
    }
  }

  &,
  &.reveal\:fade-up,
  &.reveal\:slide-down {
    > [tabindex] {
      margin: var(--modal-vertical-margin) auto;
      border-radius: $ui-border-radius-lg;
      box-shadow: $ui-box-shadow-heavy;
    }
  }

  &.scroll\:modal {
    &,
    &.reveal\:fade-up,
    &.reveal\:slide-down {
      > [tabindex] {
        max-height: var(--modal-max-height);
      }
    }
  }

  &.reveal\:slide-down {
    align-items: flex-start;
  }

  &.reveal\:slide-left > [tabindex] {
    height: 100%; // use % and not vh to prevent mobile browser UI covering part of modal
    max-height: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none;

    > .ui-component-status-bar:last-child {
      margin-top: auto;
    }
  }

  &.width\:lg,
  &:not(.width\:sm),
  &:not(.width\:md),
  &:not(.width\:xl) {
    --modal-max-width: #{breakpoint(tablet-portrait-up)};

    @include tablet-portrait-up {
      --modal-max-height: var(--modal-nominal-max-height);
    }
  }

  &.width\:sm {
    --modal-max-width: #{breakpoint(phablet-up)};

    @include phablet-up {
      --modal-max-height: var(--modal-nominal-max-height);
    }
  }

  &.width\:md {
    --modal-max-width: 600px;

    @media screen and (min-width: 600px) {
      --modal-max-height: var(--modal-nominal-max-height);
    }
  }

  &.width\:xl {
    --modal-max-width: #{breakpoint(desktop)};

    @include desktop {
      --modal-max-height: var(--modal-nominal-max-height);
    }
  }

  .ui-component-button.variant\:dismiss {
    --dismiss-button-padding: var(--space-xs);

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: var(--dismiss-button-padding);
    font-size: 1.5rem;
    text-shadow: 0px 0px 2px white; // for more visibility over images
  }

  &:not([hidden]) {
    &.--initial {
      display: flex;
    }

    &:not(.--initial) {
      animation: fadeIn var(--fade-in-duration);

      &, &.reveal\:fade-up {
        > [tabindex] {
          animation: fadeInUpGrow .2s forwards;
        }
      }

      &.reveal\:slide-left > [tabindex] {
        animation: slideInLeft .3s forwards;
      }

      &.reveal\:slide-down > [tabindex] {
        animation: slideInDown .3s forwards;
      }
    }
  }

  &[hidden] {
    &.--initial {
      display: none;
    }

    &:not(.--initial) {
      display: flex;
      animation: fadeOut var(--fade-in-duration) .2s forwards, zeroDimensions 0s .3s forwards;
      content-visibility: auto !important; // Override tailwind so animation works as expected

      &, &.reveal\:fade-up {
        > [tabindex] {
          animation: fadeOutDownShrink .2s forwards;
        }
      }

      &.reveal\:slide-left > [tabindex] {
        animation: slideOutRight .3s forwards;
      }

      &.reveal\:slide-down > [tabindex] {
        animation: slideOutUp .3s forwards;
      }
    }
  }

  &.close\:viewport {
    > [tabindex] .ui-component-button.variant\:dismiss {
      display: none;
    }

    &::before {
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      padding: var(--space-xs);
      font-size: 1.5rem !important;
      color: #fff;
      cursor: pointer;
      @include fa-pseudo("\f00d"); // fa-xmark
    }
  }

  &.dismiss\:header {
    > [tabindex] {
      padding: 0;

      > article,
      > turbo-frame > article {
        gap: 0;

        > header {
          padding: var(--space-xs) var(--modal-padding);
          background-color: #fff;
          border-bottom: 1px solid $grey-200;
          line-height: 1.5;

          > div {
            font-size: var(--font-lg);
            font-weight: 700;

            > :last-child:not(:first-child) {
              font-size: var(--base-font);
              font-weight: 300;
            }
          }

          .ui-component-button.variant\:dismiss {
            position: static;
            margin-left: calc(-1 * var(--dismiss-button-padding));
          }
        }

        > section,
        > form {
          display: flex;
          flex-direction: column;
          gap: var(--space);
          padding: var(--modal-padding);
        }
      }
    }
  }

  &.variant\:filters {
    > [tabindex] {
      padding: 0;
    }

    .ui-component-status-bar {
      width: 100%;
    }
  }

  &.variant\:exit {
    text-align: center;

    > [tabindex] > article > section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--space-sm);
    }
  }

  &.variant\:something-changed {
    header {
      margin: 0;
    }

    section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--space);

      p {
        max-width: 400px;
        text-align: center;
        margin: 0;
      }
    }
  }

  &.variant\:venue-hero {
    > [tabindex] {
      background-color: transparent;

      > article {
        gap: 0;
      }
    }

    @include mobile-max {
      &::before { // Close "x" pseudo-button
        color: $sb-green;
      }

      > [tabindex] {
        max-height: 100dvh;
        overflow: hidden;
        margin: 0;
        padding: 0;
        background-color: #fff;
        border-radius: 0;
      }

      .ui-component-carousel {
        height: 100dvh;

        [tabindex] {
          overflow-y: auto;
          flex-direction: column;
          gap: var(--space-xs);
          background: #fff;

          ~ [aria-controls] {
            display: none;
          }
        }
      }
    }
  }

  &:is(
    .variant\:venue-booking,
    .variant\:venue-package-voucher,
    .variant\:venue-monetary-voucher,
    .variant\:checkout
  ) > [tabindex] {
    > article,
    > turbo-frame > article {
      overflow: auto;
      overflow-x: hidden;

      > header {
        position: sticky;
        top: 0;
        z-index: $z-index-sticky;
      }

      > footer,
      .ui-component-drawer > footer {
        position: sticky;
        left: 0;
        bottom: 0;
        z-index: $z-index-sticky;
        display: flex;
        flex-direction: column;
        gap: var(--space-3xs);
        width: 100%;
        padding: var(--space-xs) var(--modal-padding);
        margin-top: auto;
        background-color: #fff;
        box-shadow: 0 -2px 10px 1px rgba(#000, 0.1);

        &,
        > section {
          display: flex;
          flex-direction: column;
          gap: var(--space-3xs);
        }

        > section > header > :first-child {
          text-transform: uppercase;
          font-weight: 700;
          font-size: var(--font-lg);
        }

        .ui-component-klarna-widget {
          align-self: flex-start;
          padding-left: 0;
          border: 0;
          white-space: nowrap;
        }
      }

      .ui-component-carousel {
        &,
        & > div {
          height: auto;
        }
      }

      .ui-component-drawer {
        &[data-drawer-open-value="false"] {
          > footer {
            display: none;
          }
        }

        > footer {
          position: absolute;
        }
      }
    }
  }

  &:is(
    .variant\:venue-booking,
    .variant\:venue-package-voucher,
    .variant\:venue-monetary-voucher
  ) > [tabindex] {
    background-color: $grey-100;
  }

  &.variant\:venue-booking {
    // Clamp section padding to add more space for calendar
    section.mixin\:clamped {
      --mixin-clamped-min: 1;
      --mixin-clamped-max: 1.5;

      padding: var(--modal-padding) var(--mixin-clamped) !important;
    }
  }

  &.variant\:venue-package-voucher {
    // Hide radio control if only one input in group
    .ui-component-radio-group,
    .ui-component-list.variant\:selection li:only-child {
      label:only-child {
        cursor: text;

        input[type="radio"] + span::before {
          display: none;
        }
      }
    }
  }

  &.variant\:unavailable {
    @media screen and (max-width: 600px) {
      align-items: end;

      > [tabindex] {
        margin-bottom: 0;
        border-radius: 0;
      }
    }

    .ui-component-notice {
      background-color: transparent;
      container-type: inline-size;

      > div {
        justify-content: space-between;

        @container (width <= 400px) {
          flex-direction: column;
          row-gap: var(--space-3xs);
        }

        header {
          flex-shrink: 1;
          align-self: flex-start;
          flex-direction: row;
          column-gap: var(--space-3xs);
          border: none;

          + * {
            flex-basis: fit-content;
          }
        }
      }
    }
  }

  &.variant\:checkout-gift-message > [tabindex] > article > footer {
    flex-direction: row;
    justify-content: flex-end;
    gap: var(--space-xs);

    // 'Clear all' link
    > button:first-child {
      flex-shrink: 0;
    }
  }
}

.ui-component-modal.no-style {
  .ui-component-button.variant\:dismiss {
    display: none;
  }

  &.variant\:filters {
    .ui-component-status-bar {
      display: none;
    }
  }
}

html:has(body.--modal-open),
body.--modal-open,
:is(html, body):has(.ui-component-modal:not([hidden]):not(.no-style)) {
  // it behaves similarly to overflow: hidden;
  // the overflow content is clipped and not considered by the browser for layout calculations
  // allows the sticky elements still stay in its initial position in the layout
  overflow: clip;
}
